import { gql } from 'apollo-boost';

export const listNotifications = gql`
  query($first: Int, $after: String) {
    notifications(first: $first, after: $after) {
    total
     pageInfo {
       startCursor
       endCursor
       hasNextPage
     }
     edges {
       cursor
       node {
         id
         read
         category
         createdAt
         subject
       }
     }
    }
  }
`;

export const markAsRead = gql`
  mutation markAsReadNotification(
    $id: ID!,
  ) {
    markAsReadNotification(
      id: $id,
    ) {
      id
      read
    }
  }
`;
