import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Col, Row, Spinner } from 'reactstrap';

const Loader = ({ nopadding, ...props }) => (
  <Row className={classnames('flex-center', { 'py-5' : !nopadding })}>
    <Col xs="auto">
      <Spinner {...props} />
    </Col>
  </Row>
);

Loader.propTypes = { ...Spinner.propTypes, nopadding: PropTypes.bool };

Loader.defaultProps = {
  nopadding: false,
  type: 'grow',
  size: 'lg',
  color: 'primary'
};

export default Loader;
