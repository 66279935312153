import { createContext } from 'react';

const AppContext = createContext({
  isFluid: false,
  isRTL: false,
  isDark: false,
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: 'R$',
  isNavbarVerticalCollapsed: false,
  isTopNav: false
});

export const AuthWizardContext = createContext({
  user: {},
  cities: []
});

export default AppContext;
