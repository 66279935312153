import { getItemFromStore, setItemToStore } from '../helpers/utils';

export const tokenKey = "userTokenKey";
export const isAuthenticated = () => getItemFromStore(tokenKey);
export const token = getItemFromStore(tokenKey);

export const setToken = (newToken) => {
  setItemToStore(tokenKey, newToken);
};

export const removeToken = () => {
  localStorage.removeItem(tokenKey);
};
