export const homeRoutes = {
  name: 'Home',
  to: '/dashboard',
  exact: true,
  icon: 'home',
};

export const productsRoutes = {
  name: 'Catálogo',
  to: '/products',
  exact: true,
  icon: 'book',
  children: [
    { to: '/products', name: 'Meus produtos' },
    { to: '/products/type-select', name: 'Novo produto' },
  ]
};

export default [
  homeRoutes,
  productsRoutes,
];
