import { makeVar } from "@apollo/client";

export const currentUserVar = makeVar([]);

export const hasRole = (role) => {
  const user = currentUserVar()[0];
  return user && user.roles.includes(role);
};

export const homePath = (userRoles) => {
  const availablePaths = ["products", "orders", "finances"];
  const filterRoles = userRoles.filter((role) => availablePaths.includes(role));

  return filterRoles.length > 0 ? filterRoles[0] : 'account-settings';
};
