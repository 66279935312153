import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { markAsRead } from '../apiCalls/notifications';
import { useMutation } from '@apollo/react-hooks';
import Avatar from '../common/Avatar';
import {
  notificationConfig,
  notificationMessages
} from '../../data/notification/notification';

function Notification({ flush, notification }) {
  const [handleMarkAsRead] = useMutation(markAsRead);

  const isRead = notification && notification.read;
  const time = notification && notification.createdAt;

  const notificationType = notificationConfig.find((n) => n.category === notification.category);
  const subject = JSON.parse(notification.subject)

  const isProduct = notification.category === 'product_reviewed' ? true : false;

  const notificationLinkDefault = `/${subject.id}`

  const notificationLinkProduct = `/${subject.service}/${subject.id}/revision`

  const notificationLink = isProduct ? notificationLinkProduct : notificationLinkDefault

  return (
    <div
      { ...(!notification.read && { onClick: () => handleMarkAsRead({variables: { id: notification.id }})}) }
    >
      <Link
        className={
          classNames('notification', {
            'bg-200': !isRead, 'notification-flush': flush
          }, notificationType.className)
        }
        to={`/${notificationType.baseLink}${notification.subject && notificationLink}`}
      >

      <div className="notification-avatar">
        <Avatar icon={notificationType.icon} size="3xl" className="mr-3" />
      </div>

        <div className="notification-body">
          <p className='mb-0'>
            { notificationMessages(notification.category, subject) }
          </p>

          <span className="notification-time">
            { moment(time).fromNow() }
          </span>
        </div>
      </Link>
    </div>
  )
};

Notification.propTypes = {
  flush: PropTypes.bool
};

Notification.defaultProps = { flush: false };

export default Notification;
