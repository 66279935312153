import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { token, removeToken } from './helpers/auth';
import { currentUserVar } from './helpers/roles';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

    if (networkError) {
      if (networkError.result.exception.includes('JWT::')) {
        removeToken();
      }
    }
  }),

  new HttpLink({
    uri: process.env.NODE_ENV === 'production' ?
      'https://api.parceiro.guiapass.com/graphql' :
      'http://localhost:5100/graphql',
    credentials: 'same-origin'
  })
])

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          currentUser: {
            read() {
              return currentUserVar();
            }
          }
        }
      }
    }
  }),
});

ReactDOM.render(
(  <ApolloProvider client={client}>
    <Main>
      <App />
    </Main>
  </ApolloProvider>),
  document.getElementById('main')
);
