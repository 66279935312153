import React from 'react';
import NavbarDropdown from './NavbarDropdown';
import PropTypes from 'prop-types';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { homeRoutes } from '../../routes';
import { hasRole } from '../../helpers/roles';

const NavbarTopDropDownMenus = () => {
  const financesDropdownItems = [
    {
      to: '/billings',
      name: 'Painel Financeiro'
    },
    {
      to: '/bank-accounts',
      name: 'Contas Bancárias'
    }
  ];

  return (
    <>
      {
        false &&
        <NavItem>
          <NavLink className="nav-link" to={homeRoutes.to}>
            {homeRoutes.name}
          </NavLink>
        </NavItem>
      }

      {
        hasRole('products') &&
        <NavItem>
          <NavLink className="nav-link" to="/products">
            Catálogo
          </NavLink>
        </NavItem>
      }

      {
        hasRole('orders') &&
        <NavItem>
          <NavLink className="nav-link" to="/orders">
            Reservas
          </NavLink>
        </NavItem>
      }

      {
        hasRole('finances') &&
        <NavbarDropdown
          title="Financeiro"
          items={financesDropdownItems}
        />
      }
    </>
  );
};

NavbarTopDropDownMenus.propTypes = { setNavbarCollapsed: PropTypes.func.isRequired };

export default NavbarTopDropDownMenus;
