import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Alert, Media, Card, Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem } from 'reactstrap';
import { isIterableArray } from '../../helpers/utils';
import FalconCardHeader from '../common/FalconCardHeader';
import Notification from '../notification/Notification';
import { listNotifications } from '../apiCalls/notifications';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';

const PAGE_SIZE = 5;

const NotificationDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useQuery(listNotifications, {
    variables: { first: PAGE_SIZE }
  });

  const notifications = data ? data.notifications.edges.map(edge => edge.node) : [];

  const newNotifications = notifications.filter((n) => n.read === false);

  const isAllRead = isIterableArray(notifications) && !isIterableArray(newNotifications) ? true : false

  // Handler
  const handleToggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={isOpen}
      toggle={handleToggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(false);
      }}
    >
      <DropdownToggle
        nav
        className={classNames('px-0', {
          'notification-indicator notification-indicator-primary': isIterableArray(notifications) && !isAllRead
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card dropdown-notification">
        <Card className="card-notification shadow-none" style={{ maxWidth: '20rem' }}>
          <FalconCardHeader className="card-header" title="Notificações" titleTag="h6" light={false}/>
          {
            isIterableArray(notifications) ?
            <>
              <ListGroup flush className="font-weight-normal fs--1 notification-list">
                {
                  notifications.map((notification, index) => (
                    <ListGroupItem key={index} onClick={handleToggle}>
                      <Notification notification={notification} flush />
                    </ListGroupItem>
                  ))
                }

                <div className="text-center p-2">
                  <Link to="/notifications">
                    Ver todas
                  </Link>
                </div>
              </ListGroup>
            </>
            :
            <Alert color="primary" className="mb-0 overflow-hidden rounded-0">
              <Media className="align-items-center">
                <FontAwesomeIcon icon="exclamation-triangle" />
                <Media body className="ml-3">
                  <p className="mb-0">
                    Nenhuma notificação
                  </p>
                </Media>
              </Media>
            </Alert>
          }
        </Card>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationDropdown;
