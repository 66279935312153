import React from 'react';
import { faCalendarTimes, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'

export const notificationConfig = [
  {
    category: 'new_order',
    baseLink: 'orders',
    className: 'rounded-0 border-x-0 border-300 border-bottom-0',
    icon: 'calendar-check'
  },
  {
    category: 'cancelled_order',
    baseLink: 'orders',
    className: 'rounded-0 border-x-0 border-300 border-bottom-0',
    icon: faCalendarTimes
  },
  {
    category: 'product_reviewed',
    baseLink: 'products',
    className: 'rounded-0 border-x-0 border-300 border-bottom-0',
    icon: faShoppingBag
  },
];

export const notificationMessages = (category, subject) => {
  switch (category) {
    case 'new_order':
      return (
        <>
          Nova reserva de {<b>{subject.customer}</b>}: {<b>{subject.product}</b>} para dia {<b>{moment(subject.checkin).format('DD/MM/YYYY')}</b>}.
        </>
      )
    case 'cancelled_order':
      return (
        <>
          A reserva {<b>#{subject.id}</b>} para dia {<b>{moment(subject.checkin).format('DD/MM/YYYY')}</b>} foi cancelada.
        </>
      )
    case 'product_reviewed':
      return (
        <>
          O produto {<b>{subject.product}</b>} foi revisado e está pronto para ser publicado.
        </>
      )
    default: return 'Você tem uma nova notifição'

  }
}
