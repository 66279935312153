import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import Avatar from '../common/Avatar';
import Loader from '../common/Loader';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { removeToken } from '../../helpers/auth';
import { currentUserVar } from '../../helpers/roles';

const ProfileDropdown = ({ history }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const user = currentUserVar()[0];

  const handleLogout = () => {
    removeToken();
    history.push('/');
  }

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
        {
          user ?
          <Avatar name={user.email.match(/^([^@]*)@/)[1]} /> :
          <Loader />
        }
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem tag={Link} to="/account-settings">
            <FontAwesomeIcon icon="cog" className="mr-1" />
            Configurações
          </DropdownItem>
          <DropdownItem onClick={() => handleLogout()}>
            <FontAwesomeIcon icon={faPowerOff} className="mr-1" />
            Logout
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default withRouter(ProfileDropdown);
