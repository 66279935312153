import { gql } from 'apollo-boost';

export const showUser = gql`
  query user {
    user {
      id
      email
      roles
      master
      notificationByEmail
    }
  }
`;

export const createUser = gql`
  mutation createUser(
    $email: String!
    $password: String!
    $roles: String!
  ) {
    createUser(
      email: $email,
      password: $password,
      roles: $roles
    ) {
      id
      email
      roles
    }
  }
`;

export const updateUser = gql`
  mutation updateUser(
    $email: String!
    $password: String!
    $roles: String!
    $id: ID!
  ) {
    updateUser(
      email: $email,
      password: $password,
      roles: $roles,
      id: $id
    ) {
      id
      email
      roles
    }
  }
`;

export const updateAccount = gql`
  mutation updateAccountUser(
    $id: ID!,
    $email: String!
    $password: String!
    $newPassword: String
    $newPasswordConfirmation: String
    $notificationByEmail: String!
  ) {
    updateAccountUser(
      id: $id,
      email: $email,
      password: $password,
      newPassword: $newPassword,
      newPasswordConfirmation: $newPasswordConfirmation,
      notificationByEmail: $notificationByEmail
    ) {
      id
      email
      token
      roles
      notificationByEmail
    }
  }
`;

export const signinUser = gql`
  mutation signinUser(
    $email: String!,
    $password: String!,
    $supplierId: ID
  ) {
    signinUser(email: $email, password: $password supplierId: $supplierId) {
      id
      email
      token
      roles
    }
  }
`;

export const forgotPasswordUser = gql`
  mutation forgotPasswordUser($email: String!) {
    forgotPasswordUser(email: $email) {
      email
    }
  }
`;

export const resetPasswordUser = gql`
  mutation resetPasswordUser(
    $token: String!,
    $email: String!,
    $password: String!
  ) {
    resetPasswordUser(
      token: $token,
      email: $email,
      password: $password
    ) {
      email
    }
  }
`;

export const listUsers = gql`
  query ShowSupplier(
    $supplierId: ID!,
  ) {
    supplier(
      id: $supplierId,
    ) {
      id
      users {
        id
        email
        roles
      }
    }
  }
`;

export const destroyUser = gql`
  mutation destroyUser(
    $id: ID!
  ) {
    destroyUser(
      id: $id,
    ) {
      id
    }
  }
`;
